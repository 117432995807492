@tailwind base;
@tailwind components;
@tailwind utilities;

/* src/styles.css */

/* Set global body styles */
/* src/styles.css */

/* Set global body styles */
body {
  font-family: 'Arial', sans-serif;
  background-color: #121212; /* Dark background */
  color: #ffffff; /* Light text color */
}

/* Set custom colors */
.bg-dark-bg {
  background-color: #121212;
}

.text-heading {
  color: #64ffda; /* Turquoise color */
}

.text-content {
  color: #c4c4c4; /* Light gray color */
}

.text-turquoise {
  color: #64ffda; /* Turquoise color */
}

/* Styling for buttons */
.btn-login,
.btn-signup {
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.btn-login {
  background-color: #64ffda;
  color: #121212;
  margin-right: 1rem;
}

.btn-signup {
  background-color: #1f2937;
  color: #64ffda;
}

.btn-login:hover,
.btn-signup:hover {
  background-color: #4ac3a2; /* Darker turquoise on hover */
}

/* Image placeholder */
.bg-image-placeholder {
  background-color: #242424;
}

