body {
  background-color: #f8f9fa;
  font-family: Arial, sans-serif;
}

.intro-container {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.intro-title {
  font-size: 3em;
  color: #007bff;
  margin-bottom: 10px;
}

.intro-description {
  font-size: 1.2em;
  color: #6c757d;
  margin-bottom: 20px;
}

.button-container {
  margin-top: 20px;
}

/* .card {
  border: none;
  border-radius: 10px;
  transition: transform 0.2s ease-in-out;
} */

/* .card:hover {
  transform: scale(1.05) rotate(5deg);
} */

.card-img-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 250px;
  object-fit: cover;
  transition: transform 0.2s ease-in-out;
}

.card-img-top:hover {
  transform: rotate(5deg);
}

.text-center {
  text-align: center;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

footer {
  background-color: #f8f9fa;
  color: #343a40;
  padding: 20px;
  text-align: center;
  border-top: 1px solid #dee2e6;
}

footer p {
  margin: 0;
}

footer h5 {
  margin-bottom: 10px;
}

.team-card {
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.team-card:hover {
  transform: scale(1.05);
}

.team-card .card-img {
  object-fit: cover;
  height: 250px;
}

.carousel-item {
  transition: transform 1s ease-in-out;
}
